import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import { formatDate } from './format'

export function getPaymentMethodDisplayName(paymentMethodData: PaymentMethod): string {
  switch (paymentMethodData.type) {
    case 'card':
      return `Carte n° XXXX XXXX XXXX ${paymentMethodData.last4}`
    case 'sepa_debit':
      return paymentMethodData.last4
        ? `Prélévement SEPA (IBAN se terminant par ${paymentMethodData.last4})`
        : `Prélévement SEAP ajouté le ${formatDate(paymentMethodData.creationTimestamp)}`
  }
}
