import styled from '@emotion/styled'
import type { ActivableProductInformationId } from '@orus.eu/dimensions'
import { spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { ActivableProductInformationIdsBadgesList } from './activable-product-information-ids-badges-list'

export const SubscriptionActivityProducts = memo<{
  activityName: string | undefined
  activableProductInformationIds: ActivableProductInformationId[]
}>(function SubscriptionActivityProducts({ activityName, activableProductInformationIds }) {
  return (
    <FlexRow>
      <ActivableProductInformationIdsBadgesList activableProductInformationIds={activableProductInformationIds} />
      <Text variant="body2">{activityName ?? '[Activité à renseigner]'}</Text>
    </FlexRow>
  )
})

const FlexRow = styled.div`
  display: flex;
  gap: ${spacing[50]};
`
